<template>
  <div id="app">
    <div class="container shadow">
        <div class="jumbotron p-3 p-md-5 rounded bg-gps">
          <!-- <div class="d-flex justify-content-center"><img id="gps-logo" src="./assets/Logo_gPS_Halbkreis_RGB.png"></div> -->
          <div class="col-md-6 offset-md-3 text-center px-0">
            <h1 class="display-4 font-italic">Preisrechner Drohnenflüge</h1>
            <p class="lead my-3">Verwenden Sie unseren einfachen Preisrechner um eine Kostenabschätzung für Ihre Befliegung zu erhalten</p>
          </div>
        </div>
    <div class="row">
          <div class="col-md-12">
    <h3 class="text-center">1. Produkt wählen</h3>
    <p class="text-center text-muted">Klicken Sie das gewünschte Produkt an, um es auszuwählen.</p>
    <br>
    <!-- Main Row -->
    <div class="row justify-content-md-center">
      <div class="col-md-3" v-for="prod in products" v-bind:key="prod.id">
        <div class="card" v-bind:class="{ active: prod.active }" v-on:click="selectProduct(prod)">
          <div class="card-body text-center">
            <font-awesome-icon :icon="prod.icon" size="6x"></font-awesome-icon>
            <h5 class="card-title">{{ prod.name }}</h5>
            <h6 class="card-subtitle mb-2" v-if="prod.additionalDesc"><small>{{ prod.additionalDesc }}</small></h6>
            <p class="card-text">{{ prod.desc }}</p>
            <div class="input-group mb-3" v-if="prod.priceFactors && prod.active">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">Auflösung</label>
              </div>
              <select v-model="total.multiplier" class="custom-select" id="inputGroupSelect01" v-on:change="selectAccuracy()" >
                <option :selected=" option.default == true " v-for="option in prod.priceFactors" v-bind:key="option.id" v-bind:value="option.factor">{{ option.desc }}</option>
              </select>
              <small id="accuracyHelp" class="form-text">Je feiner die Auflösung, umso genauer sind die Daten.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>    
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h3 class="text-center">2. Fläche definieren</h3>
        <p class="text-center text-muted">Wählen Sie die gewünschte Fläche in der Karte mithilfe des Flächensymbols...</p>
        <div id="map"></div><br>
        <p class="text-center text-muted">...oder geben Sie direkt die Grösse der Fläche ein.</p>
        <div class="input-group mb-3 col-md-6 offset-md-3">
          <input id="areaToFly" v-model="total.area" v-on:input="updateTotal()" type="number" min="0" class="form-control text-center" placeholder="Fläche der Befliegung" aria-label="Fläche der Befliegung" aria-describedby="basic-addon2">
          <input id="encodedArea" type="hidden" v-model="total.encodedArea">
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">m<sup>2</sup></span>
          </div>
          <small id="areaToFlyHelp" class="form-text text-muted">Wir benötigen diese Angabe zur Berechnung des Preises.</small>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-4 offset-md-2">
        <h3 class="text-center">3. Extras auswählen</h3>
        <p class="text-center text-muted">Wählen Sie optionale Extras.</p>
        <div v-for="addon in addons" v-bind:key="addon.id" class="custom-control custom-checkbox">
          <input v-on:click="selectAddon(addon)" type="checkbox" class="custom-control-input" :id="addon.id" :disabled="addon.preselected" :checked="addon.active">
          <label class="custom-control-label" :for="addon.id">{{ addon.name }} ({{ addon.price }} CHF)</label>
        </div>
      </div>
      <div class="col-md-4">
        <h3 class="text-center">4. Preiskalkulation</h3>
        <p class="text-center text-muted">Erfahren Sie den ungefähren Preis und nehmen Sie Kontakt mit uns auf.</p>
        <h6 v-if="(total.area === 0 || total.area === null || total.prod === null)" class="text-muted text-center heading-light"><i>Bitte ein Produkt wählen.</i></h6>
        <ul v-if="total.area > 0 && total.prod !== null" class="list-group mb-3">
          <li v-for="prod in products" v-if="prod.active === true" v-bind:key="prod.id" class="list-group-item d-flex justify-content-between lh-condensed">
            <div>
              <h6 class="my-0">{{ prod.name }}</h6>
            </div>
            <span class="text-muted">{{ Math.round( ( prod.priceFactors ?  (total.prod.ignoreArea === true ? prod.pricePerUnit * total.multiplier + prod.fixedRate : total.area * prod.pricePerUnit * total.multiplier + prod.fixedRate ) : (total.prod.ignoreArea === true ? prod.pricePerUnit + prod.fixedRate : total.area * prod.pricePerUnit + prod.fixedRate )) * 100 ) / 100  }}.-</span>
          </li>
          <li v-for="addon in addons" v-if="addon.active === true" v-bind:key="addon.id" class="list-group-item d-flex justify-content-between lh-condensed">
            <div>
              <h6 class="my-0">{{ addon.name }}</h6>
            </div>
            <span class="text-muted">{{ addon.price }}.-</span>
          </li>
          <li class="list-group-item d-flex justify-content-between lh-condensed bg-light">
            <div>
              <h6 class="my-0">Gesamtbetrag <span class="text-muted">(exkl. MwSt.)</span></h6>
              <small class="text-muted">Unverbindliche Preiskalkulation</small><br>
            </div>
            <span class="text-muted"><strong> {{ total.amount }}.-</strong></span>
          </li>
          <div v-if="total.area > 0 && total.prod !== null" class="text-center">
            <hr>
            <contact-form v-bind:requestAddons="addons" v-bind:requestData="total"></contact-form>
          </div>
        </ul>
      </div>
    </div>
    <!-- /Main Row -->
    </div>
  </div>
  <hr>
  <footer class="text-center text-muted">
    <small>Alle Preise sind unverbindlich und dienen lediglich der Information.<br>Drohnenpreisrechner 1.0 by geoPro Suisse AG<br>
          Made with <font-awesome-icon icon="heart" :style="{ color: 'red' }"></font-awesome-icon> and <font-awesome-icon icon="coffee" :style="{ color: 'brown' }"></font-awesome-icon> in Baden, Switzerland</small>
    </footer>
  </div>
  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'

// Import component
import contactForm from './components/contactForm.vue'

export default {
  name: 'app',
  components: {
    contactForm
  },
  data: function() {
    return {      
      total: {
          amount: 0,
          area: null,
          multiplier: 1.0,
          prod: null,
          encodedArea: null,
        },
      products: [
        {
          id: 1,
          name: 'Luftbild',
          pricePerUnit: 0.01,
          priceFactors: [
            {
              id: 1000,
              desc: '5mm',
              factor: 2.5
            },
            {
              id: 1001,
              desc: '15mm',
              factor: 1.5
            },
            {
              id: 1002,
              desc: '30mm',
              factor: 1.0,
              default: true
            }
          ],
          unit: 'm2',
          fixedRate: 275,
          icon: 'map',
          desc: 'Hochaufgelöstes Orthophoto, korrekt georeferenziert und zur weiteren Verarbeitung vorbereitet.',
          active: false
        },
        {
          id: 2,
          name: 'Dachaufnahme',
          pricePerUnit: 0.15,
          priceFactors: [
            {
              id: 1000,
              desc: '5mm',
              factor: 2.5
            },
            {
              id: 1001,
              desc: '15mm',
              factor: 1.5
            },
            {
              id: 1002,
              desc: '30mm',
              factor: 1.0,
              default: true
            }
          ],
          unit: 'm2',
          fixedRate: 275,
          icon: 'home',
          desc: 'Extrem genaue Aufnahmen Ihres Dachs z.B. zur Abklärung des Solarenergiepotenzials.',
          additionalDesc: 'inkl. Orthophoto',
          active: false
        },
        {
          id: 3,
          name: 'DGM',
          pricePerUnit: 0.27,
          priceFactors: [
            {
              id: 1000,
              desc: '5mm',
              factor: 2.5
            },
            {
              id: 1001,
              desc: '15mm',
              factor: 1.5
            },
            {
              id: 1002,
              desc: '30mm',
              factor: 1.0,
              default: true
            }
          ],
          unit: 'm2',
          fixedRate: 275,
          icon: 'chart-area',
          desc: 'Extrem genaues, digital optimiertes Geländemodell von beliebiger Grösse.',
          additionalDesc: 'inkl. Orthophoto',
          active: false
        },
        /*{
          id: 4,
          name: 'Ansichten',
          pricePerUnit: 25,
          unit: 'm2',
          fixedRate: 165,
          icon: 'video',
          desc: 'Erhalten Sie Video- und Fotoansichten Ihres Objekts. Ideal etwa zur Vermarktung von Hausverkäufen.',
          active: false,
          ignoreArea: true
        },*/
      ],
      addons: [
        {
          id: 101,
          name: "Pix4D-Cloudzugang (inbegriffen)",
          price: 0,
          active: true,
          preselected: true
        },
        {
          id: 102,
          name: "Datendownload",
          price: 55,
          active: false
        }
      ]
    }
  },
  mounted() {
    // Initialize Map
    this.initMap();
  },
  methods: {
    /**
     * selectProduct: Fired when selecting a product
     * - Deactivates all products
     * - Sets the selected product to active (for CSS)
     * - Adds the selected product to the total object, since it's easier than looking for the objects all the time
     * - Updates the total
     */
    selectProduct: function (obj) {
      // Deactivate other fields
      this.products.forEach(function(ele) {
        ele.id != obj.id ? ele.active = false : null
      })
      obj.active = true

      // Add object to total object and update total
      this.total.prod = obj
      this.updateTotal()
    },
    /**
     * selectAddon: Fired when selecting an extra option
     * - Checks for activity state and deactivates it
     * - Adds or subtracts the amount from the total
     */
    selectAddon: function (obj) {
      if( obj.active === true ) {
        obj.active = false
        this.total.amount = this.total.amount - obj.price
      } else {
        obj.active = true
        this.total.amount = this.total.amount + obj.price
      }
    },
    /**
     * selectAccuracy: Fired when selecting an accuracy from the dropdown
     * - Updates the grandtotal (since select is attached via v-model to total.multiplier)
     */
    selectAccuracy: function() {
      this.updateTotal()
    },
    /**
     * updateTotal: Main method that updates the total amount
     * - Checks for area, if null, then area is 0
     * - If price factors exist, we factor them in, else we ignore them
     * - Adds any chosen addons to the total amount
     */
    updateTotal() {
      if(this.total.area === null) {
        this.total.area = 0
      }

      if(this.total.area <= 0) {
        this.total.area = -1 * this.total.area
      }

      // Check if pricefactors exists; if so, we need to take the multiplier. Do this only, if we have a product stored.
      if(this.total.prod) {
        // If area is 0, do not calculate the total amount
        if(this.total.area === 0) {
          this.total.amount = 0
        } else {
          // Only factor in area if we need to
          if(this.total.prod.ignoreArea){
            this.total.prod.priceFactors ? this.total.amount = this.total.prod.fixedRate +  this.total.prod.pricePerUnit * this.total.multiplier : this.total.amount = this.total.prod.fixedRate + this.total.prod.pricePerUnit
          } else {
            this.total.prod.priceFactors ? this.total.amount = this.total.prod.fixedRate + this.total.area * ( this.total.prod.pricePerUnit * this.total.multiplier) : this.total.amount = this.total.prod.fixedRate + this.total.area * this.total.prod.pricePerUnit
          }
        }
      }
      // Round everything... Not the best rounding method, but works for this one :)
      this.total.amount = Math.round(this.total.amount * 100)/100
      this.addons.forEach(function(ele) {  
        if(ele.active) {
          this.total.amount = this.total.amount + ele.price
        }
      }, this)
    },

    /**
     * initMap: Instantiates the map where areas can be drawn.
     * - Adds event listener to check for existing areas, which then have to be deleted
     * - Updates the area field
     */
    initMap() {
      this.map = L.map('map', {
        measureControl: true
      }).setView([47.47287, 8.30725], 13);
      this.tileLayer = L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          maxZoom: 18,
          attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        }
      )
      this.tileLayer.addTo(this.map);
      
      // Set up Leaflet-Draw
      L.drawLocal.draw.toolbar.actions.text = 'Abbrechen'
      L.drawLocal.draw.toolbar.actions.title = 'Zeichnung abbrechen'
      L.drawLocal.draw.toolbar.undo.text = 'Letzten Punkt löschen'
      L.drawLocal.draw.toolbar.undo.title = 'Letzten Punkt löschen'
      L.drawLocal.draw.toolbar.finish.text = 'Abschliessen'
      L.drawLocal.draw.toolbar.finish.title = 'Zeichnung abschliessen'
      L.drawLocal.draw.toolbar.buttons.polygon = 'Fläche messen'
      L.drawLocal.draw.handlers.polygon.tooltip.start = 'Klicken, um ersten Punkt abzusetzen.'
      L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Weitere Punkte hinzufügen, um Fläche zu erstellen.'
      L.drawLocal.draw.handlers.polygon.tooltip.end = 'Ersten Punkt anklicken, um Messung zu beenden.'
      let drawnItems = new L.featureGroup().addTo(this.map)
      this.map.addControl(new L.Control.Draw({
        draw: {
          circle: false, 
          polyline: false,
          rectangle: false,
          marker: false,
          circlemarker: false,
          polygon: {
            allowIntersection: false,
            showArea: false,
            drawError: {
              color: '#dc3545',
              message: 'Die Fläche darf sich nicht überschneiden!'
            },
            shapeOptions: {
                color: '#007bff'
            }
          }
        }
      }))

      // Add hook that listens on added polygons 
      this.map.on(L.Draw.Event.CREATED, function (e) {
        drawnItems.addLayer(e.layer)
        // Add hashed area to the hidden input field
        this.total.encodedArea = btoa(JSON.stringify(drawnItems.toGeoJSON()))

        this.total.area = Math.floor(L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]))
        this.updateTotal()
      }, this, document)

      // Add hook that listens on added polygons 
      this.map.on(L.Draw.Event.DRAWSTART, function () {
        drawnItems.clearLayers()
        // Reset encoded area
        this.total.encodedArea = null
        this.total.area = 0
        this.updateTotal()
      }, this)
    }
  }
}
</script>

<style>

.card:hover {
  background-color: #007bff;
  color: #FFFFFF;
}

.active {
  background-color: #007bff!important;
  color: #FFFFFF;
}

 .bg-gps {
  background-image: url("./assets/Logo_gPS_Halbkreis_RGB.png");
  background-repeat: no-repeat;
  background-size: cover;
  
  /* background-image: linear-gradient(to bottom, rgba(255, 255,255,0.4), rgba(255, 255,255,0.4)), url("./assets/splash.jpg");
  background-image: -moz-linear-gradient(top, rgba(255, 255,255,0.4), rgba(255, 255,255,0.4)), url("./assets/splash.jpg");
  background-image: -o-linear-gradient(top, rgba(255, 255,255,0.4), rgba(255, 255,255,0.4)), url("./assets/splash.jpg");
  background-image: -ms-linear-gradient(top, rgba(255, 255,255,0.4), rgba(255, 255,255,0.4)), url("./assets/splash.jpg");
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255,255,0.4)), to(rgba(255, 255,255,0.4))), url("./assets/splash.jpg");
  background-image: -webkit-linear-gradient(top, rgba(255, 255,255,0.4), rgba(255, 255,255,0.4)), url("./assets/splash.jpg"); */
} 

.heading-light {
  font-weight: 100;
}

footer {
  padding-bottom: 20px;
}

#map { 
  width: 100%;
  height: 300px; 
}

#gps-logo {
  max-height: 50px;
}
</style>
