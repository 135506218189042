<template>
  <div id="contactForm">    
    <div id="formSuccess" class="alert alert-success" v-if="isSent === true" role="success">
      <strong>Vielen Dank für Ihre Anfrage.</strong><br>
      Wir haben Ihre Nachricht erhalten und werden uns bald bei Ihnen melden.<br><br><small>Eine Bestätigung wurde an <strong>{{ confirmedEmail }} </strong> gesendet.</small>
    </div>
  
    <div id="formWrapper" v-if="isSent === false">
      <p class="text-center text-muted">Interessiert? Dann nehmen Sie direkt mit uns Kontakt auf. Füllen Sie das Formular aus und wir melden uns baldmöglichst bei Ihnen.</p>
      <div id="formError" class="alert alert-danger" v-if="error === true" role="alert">
        Fehler beim Verarbeiten. Bitte beheben Sie die folgenden Fehler: <br>
        <ul><span v-html="this.errorMsg"></span></ul>
      </div>
      <form id="formBody" v-on:submit.prevent="submitForm()">
        <div class="form-row">
          <div class="col">
            <small id="accuracyHelp" class="form-text">Vorname</small>
            <input id="firstName" type="text" class="form-control" placeholder="Max" v-model="contact.firstName" required>
          </div>
          <div class="col">
            <small id="accuracyHelp" class="form-text">Nachname</small>
            <input id="lastName" type="text" class="form-control" placeholder="Muster" v-model="contact.lastName" required>
          </div>
        </div>
        <br>
        <div class="form-row">
          <div class="col">
            <small id="accuracyHelp" class="form-text">Strasse</small>
            <input id="street" type="text" class="form-control" placeholder="Musterstrasse 23" v-model="contact.street" required>
          </div>
        </div>
        <br>
        <div class="form-row">
          <div class="col-md-4">
            <small id="accuracyHelp" class="form-text">PLZ</small>
            <input id="plz" type="number" min="0" class="form-control" placeholder="PLZ" v-model="contact.plz" required>
          </div>
          <div class="col-md-8">
            <small id="accuracyHelp" class="form-text">Ort</small>
            <input id="city" type="text" class="form-control" placeholder="Musterhausen" v-model="contact.city" required>
          </div>
        </div>
        <br>
        <div class="form-row">
          <div class="col">
            <small id="accuracyHelp" class="form-text">Emailadresse</small>
            <input id="email" type="text" class="form-control" placeholder="max.muster@musterseite.com" v-model="contact.email" required>
          </div>
        </div>
        <br>
         <div class="form-row">
          <div class="col">
            <small id="accuracyHelp" class="form-text">Telefonnummer</small>
            <input id="phone" type="text" class="form-control" maxlength="20" placeholder="+41 56 200 22 18" v-model="contact.phone" required>
          </div>
        </div>
        <br>
        <div class="form-row">
          <div class="col">
            <small id="accuracyHelp" class="form-text">Bemerkung (optional)</small>
            <textarea id="comment" class="form-control" rows="3" placeholder="Zusatzwünsche, Anmerkungen, ..." v-model="contact.comment"></textarea>
          </div>
        </div>
        <br>
        <div id="captchaWrapper" class="form-row">
          <vue-recaptcha id="phone" @expired="onCaptchaExpired" @verify="onCaptchaVerified" v-bind:sitekey="reCaptchaKey" ref="recaptcha" required>
          </vue-recaptcha>
        </div>
        
        <hr>
        <div id="submitArea" class="row">
          <div class="col">
            <button id="submitForm" class="btn btn-primary">Offerte anfordern</button>
            <small id="submitDisclaimer" class="form-text text-muted">Bitte beachten Sie, dass die Preisangaben sowie die Offertanfrage unverbindlich sind. Je nach Länge des Anfahrtsweg wird zusätzlich noch eine individuelle Wegpauschale berechnet.</small>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import request from 'request'
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'contactForm',
  props: ['requestData', 'requestAddons'],
  components: { VueRecaptcha },
  data: function() {
    return {
      contact: {
        firstName: null,
        lastName: null,
        street: null,
        plz: null,
        city: null,
        email: null,
        comment: null
      },
      token: null,
      isSent: false,
      error: false,
      errorMsg: '',
      confirmedEmail: null,
      reCaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY
    }
  },
  methods: {
    onCaptchaVerified(recaptchaToken) {
      this.token = recaptchaToken
    },
    onCaptchaExpired() {
      this.token = null
    },
    submitForm () {
      // Validate form fields.. ugly, but it works :D
      let error = false;
      // Check for null values
      Object.keys(this.contact).forEach(function(key) {
        if(key !== 'comment') {
          if(this.contact[key] === null) {
            document.getElementById(key).className = 'form-control is-invalid'
            error = true;
          }
          else {
            document.getElementById(key).className = 'form-control is-valid'
          }
        }
      }, this);

      // Check if captcha has been done
      if(this.token === null) {
        error = true
        this.errorMsg = '<li>reCaptcha bitte ausfüllen.</li>'
      }
      
      // If error happened, reset recaptcha and show error message
      if(error === true) {
        this.error = true
        this.$refs.recaptcha.reset();
      } else {
        this.error = false
        
        // Closure to capture this inside the promise
        let self = this
        request.post(          
          {url: process.env.VUE_APP_BACKEND_API, 
            form:{
              data: this.requestData,
              addons: this.requestAddons,
              contact: this.contact,
              reCaptcha: this.token
            }
          },
          function(err, httpresponse, body) {
            self.$refs.recaptcha.reset();
            if(httpresponse.statusCode !== 200) {
              self.errorMsg = body
              self.error = true
            }
            else {
              // Reset all contact elements
              Object.keys(self.contact).forEach(function(key) {
                self.contact[key] = null
              })
              self.isSent = true
              self.confirmedEmail = body
            }
          }
        )
      }
    }
  }
}
</script>

<style>
</style>
