import Vue from 'vue'
import App from './App.vue'

// Vue Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Vue FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMap, faHome, faVideo, faChartArea, faHeart, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Polyfill for older IE suppoert
import 'babel-polyfill'

library.add(faMap, faHome, faVideo, faChartArea, faHeart, faCoffee)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
